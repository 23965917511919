import { track } from "jiffy-analytics";
import MixpanelEventClass from "common/utils/ui/mixpanel-event-class";
import { observeElementVisibility, isScrollOnTop } from "common/utils/ui/visibility";

import "./payment-logos.scss";

/* eslint-disable no-new */
new MixpanelEventClass({
  eventSelector: ".js-payment-logos-footnote-button",
  mixpanelEventOptions: {
    eventName: "financing_options_footnote_clicked",
    eventPayload: {
      context: "product_detail"
    }
  }
});

const getPaymentLogosContainer$ = () => document.querySelector(".payment-logos.payment-logos--pdp");

document.addEventListener("DOMContentLoaded", () => {
  if (isScrollOnTop()) {
    observeElementVisibility(
      getPaymentLogosContainer$(),
      (entries, scrolled) => {
        track("viewed_on_pdp", { target: "payment_options", scrolled });
      },
      { mobileOnly: true, once: true }
    );
  }
});
